/*#region Navbar */

.primary-header {
  padding-bottom: var(--size-400);
  background-color: var(--clr-neutral-white);
}

.upper-nav {
  background-color: var(--clr-accent-700);
  color: var(--clr-neutral-white);
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  padding: 0.8rem 0;
}

.upper-nav-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.upper-nav-header img {
  height: 1.6rem;
}

.upper-nav-header-text {
  font-size: var(--fs-625);
}

.upper-nav-content {
  display: grid;
  grid-template-columns: 1fr;
  margin: auto;
  text-align: center;
}

.upper-nav-content-header {
  font-size: var(--clr-accent-400);
}

.upper-nav-content-time {
  font-size: var(--clr-accent-500);
  font-weight: var(--fw-bold);
}

.nav {
  --max-width: 1700px;
  --container-padding: 2rem;
  padding-top: 1rem;
  width: min(var(--max-width), 100% - (var(--container-padding) * 2));
  margin-inline: auto;
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  margin-bottom: 1rem;
}

.nav-logo {
  margin: auto 0;
  max-width: 12rem;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-mobile img {
  width: 3rem;
  cursor: pointer;
}

@media (min-width: 30em) {
  .nav-mobile {
    display: none !important;
  }

  .nav-extended-links {
    display: block !important;
  }
}

@media (max-width: 30em) {
  .nav-extended-links {
    display: none !important;
  }

  .nav-mobile {
    display: block !important;
  }
}

.nav-mobile-links {
  position: absolute;
  width: 100%;
  z-index: 102;
  display: grid;
  padding-top: 1.5rem;
  gap: 0.6rem;
  text-align: left;
  justify-content: flex-start;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  background-color: var(--clr-neutral-white);
}

.nav-link {
  font-weight: var(--fw-bold);
  cursor: pointer;
  text-decoration: none;
  color: var(--clr-neutral-black);
  font-size: var(--fs-625);
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  padding: 0.5rem 0 0.5rem 2rem;
}

.nav-link {
  text-align: left;
}

/*#endregion*/

/*#region Hero */

.hr-wrapper {
  position: relative;
  padding-top: 1rem;
}

.hr-background-img {
  width: 100%;
  margin-top: -3rem;
  z-index: 1;
}

.hr-heading {
  display: grid;
  margin: auto;
  padding: 0rem 1rem;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  line-height: 1;
  gap: 0.8rem;
  z-index: 3;
  margin-top: -1rem;
  margin-bottom: 1.8rem;
}

.hr-header {
  font-size: var(--fs-750);
  font-weight: var(--fw-bold);
}

.hr-sub-header {
  font-size: var(--fs-700);
  font-weight: var(--fw-bold);
}

.hr-tert-header {
  font-size: var(--fs-600);
  font-weight: var(--fw-bold);
  max-width: 36ch;
  line-height: 1.4;
}

.hr-info-card {
  position: absolute;
  z-index: 99;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  border-radius: 1rem;
  box-shadow: 0 1.125em 1em -1em var(--clr-neutral-black-bs);
  background-color: var(--clr-accent-700);
  color: var(--clr-neutral-white);
  width: 80%;
  left: 0;
  margin: auto auto -7rem auto;
  padding: 1.5rem 0.8rem;
  @media (max-width: 30em) {
    bottom: 0;
    right: 0;
  }
}

.hr-info-card > .btn {
  margin-top: 0.5rem !important;
  width: 80%;
  margin: auto;
  font-size: var(--fs-500);
}

.hr-info-header {
  font-size: var(--fs-625);
  font-weight: var(--fw-bold);
}

.hr-info-time {
  font-size: var(--fs-600);
  max-width: 30ch;
  margin: auto;
}

/*#endregion Hero */

/*#region Services */

#leistungen {
  padding-top: 7.5rem;
  background-color: var(--clr-neutral-white-3);
  padding-bottom: 1rem;
}

.service-welcome-text {
  display: grid;
  gap: 0.2rem;
  text-align: justify;
  justify-content: center;
  margin: auto;
  margin-bottom: 2rem !important;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.service-welcome-text p {
  max-width: 44ch;
  hyphens: auto;
}

.service-welcome-text-header {
  margin-bottom: 0.5rem;
  font-weight: var(--fw-bold);
}

.service-card-slider {
}

.service-card-list-control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem auto auto auto;
}

.service-card-dots {
  display: flex;
  gap: 1rem;
  cursor: pointer;
}

.service-card-dot {
  width: 1.5rem;
  height: 1.5rem;
  background-color: var(--clr-accent-500);
}

.service-card-dot-active {
  background-color: var(--clr-accent-700) !important;
  cursor: pointer;
}

.service-header {
  font-size: var(--fs-650);
  color: var(--clr-gray-text);
  text-align: center;
  margin-bottom: 2.5rem;
}

.service-card {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
}

.service-card-img-wrapper {
  background-color: var(--clr-accent-700);
  border-radius: 10rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 9rem;
  width: 9rem;
  margin-bottom: 0.8rem;
}

.service-card-img-wrapper-active {
  background-color: var(--clr-neutral-white-3);
  border: 4px solid var(--clr-accent-700);
}

.service-card-img {
  height: 5rem;
  width: 5rem;
}

.service-card-header {
  font-size: var(--fs-625);
  font-weight: var(--fw-bold);
}

.service-card-description {
  font-size: var(--fs-500);
  max-width: 32ch;
}

#leistungen .btn {
  width: 80%;
  margin: auto;
  margin-top: 4rem;
}

/*#endregion Services */

/*#region About */

#uber-uns {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.ab-info-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.ab-header {
  font-weight: var(--fw-bold);
  font-size: var(--fs-650);
}

.ab-text {
  font-size: var(--fs-500);
  line-height: 2;
  max-width: 36ch;
  hyphens: auto;
  text-align: justify;
}

.ab-img-wrapper {
  margin-top: 2rem;
}

.ab-img {
  border-radius: 0.5rem;
}

/*#endregion About */

/*#region Team */

#team {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  background-color: var(--clr-neutral-white-3);
}

.team-header {
  font-weight: var(--fw-bold);
  font-size: var(--fs-650);
  text-align: center;
}

.team-slider {
  display: grid;
  grid-template-columns: 1fr;
  margin: auto;
  margin-top: 1rem;
}

.team-slider-info {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-end;
}

.team-slider-info-panel {
  background-color: var(--clr-neutral-white);
  box-shadow: 0 1.125em 1em -1em var(--clr-neutral-black-bs);
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem 1.4rem;
  gap: 2rem;
}

.team-slider-pagination {
  display: flex;
  gap: 0.6rem;
  justify-content: center;
}

.team-slider-dot {
  width: 1rem;
  height: 1rem;
  background-color: var(--clr-accent-500);
  cursor: pointer;
}

.team-slider-dot-active {
  background-color: var(--clr-accent-700);
}

.team-slider-info-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.2rem;
  text-align: left;
}

.team-slider-info-name {
  font-size: var(--fs-650);
}

.team-slider-info-job {
  color: var(--clr-gray-text);
  font-size: var(--fs-600);
}

.team-slider-info-mail {
  font-size: var(--fs-500);
  color: var(--clr-accent-700);
  margin-top: 1rem;
}

.team-slider-img {
  position: relative;
}

.team-slider-control {
  background-color: var(--clr-accent-700);
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 8rem;
  height: 4rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.team-slider-chevron-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.6rem 0.2rem;
}

.team-slider-chevron-wrapper[datatype="left"] {
  background-color: var(--clr-accent-500);
}

.team-slider-chevron-wrapper img {
  height: 1rem;
}

/*#endregion Team */

/*#region Contact */

.contact-info {
  margin-bottom: 4rem;
}

.contact-heading {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  text-align: left;
}

.contact-sub-header {
  font-size: var(--fs-600);
  font-weight: var(--fw-bold);
  color: var(--clr-accent-700);
}

.contact-header {
  font-size: var(--fs-700);
  font-weight: var(--fw-bold);
}

.contact-info-panel-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  gap: 1rem;
  margin: 2rem auto 2rem auto;
}

.contact-info-panel a {
  text-decoration: none;
  color: var(--clr-neutral-black);
  cursor: pointer;
}

.contact-info-panel {
  display: grid;
  grid-template-columns: 0.1fr 0.9fr;
  gap: 0.8rem;
  align-items: center;
}

.contact-info-panel-icon {
  height: 2rem;
  width: 2rem;
}

.contact-appoinment-info {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact-appoinment-info-text {
  max-width: 42ch;
  text-align: justify;
  font-size: var(--fs-500);
}

.contact-appoinment-info .btn {
  width: 80%;
  margin: auto;
  font-size: var(--fs-500);
}

.form-submit-message p {
  max-width: 100%;
  font-size: var(--fs-400);
  text-align: justify;
  hyphens: auto;
}

/*#endregion Contact */

/*#region Form */

.contact-form-wrapper {
  margin-bottom: 2rem;
}

form[data-type="contact"] {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

form[data-type="contact"] .btn {
  width: 80%;
  margin: auto;
  margin-top: 0.6rem;
}

.form-control {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.1rem;
}

.form-control label {
  font-size: var(--fs-400);
  color: var(--clr-blue-accent-100);
  font-weight: var(--fw-bold);
}

.form-control input {
  padding: 0 0.3rem;
}

.form-control textarea {
  height: 16rem;
  border-radius: 0.5em;
  padding: 0.5rem;
}

.form-control-info {
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
}

.form-control-info input {
  height: 2rem;
  width: 2rem;
}

.form-control-info span {
  font-size: var(--fs-300);
}

/*#endregion Form */

/*#region Footer */

.primary-footer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 2em;
  background-color: var(--clr-accent-700);
  height: 28rem;
  padding: 0rem 2rem;
  padding-top: 0;
}

@media (max-width: 50em) {
  .primary-footer-wrapper > * {
    margin-inline: auto;
  }
}

.primary-footer-officeTimes {
  width: 96%;
  display: grid;
  grid-template-columns: 1fr;
  margin: 0 auto;
  margin-top: 3rem;
}

.primary-footer-officeTimes-header {
  color: var(--clr-neutral-white);
  font-size: var(--fs-600);
  margin-bottom: 1rem;
}

.primary-footer-officeTimes-item {
  display: grid;
  grid-template-columns: 0.1fr 0.9fr;
  color: var(--clr-neutral-white);
  align-items: flex-start;
  justify-content: flex-start;
}

.primary-footer-officeTimes-item p {
  font-size: var(--fs-400);
}

.primary-footer-links {
  display: inline-flex;
  justify-content: center;
  gap: clamp(var(--size-200), 30vw, var(--size-900));
  color: var(--clr-neutral-white);
}

.primary-footer-nav {
  display: flex;
  gap: 3rem;
  margin: 0 auto;
  align-items: center;
}

.primary-footer-nav a {
  color: var(--clr-neutral-white);
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  text-align: left;
}

.footer-logo-links img {
  height: var(--size-700);
}

/*#endregion*/

/*#region Utility classes */

.document-hidden {
  display: none;
  visibility: hidden;
}

.paragraph-wrapper {
  text-align: center;
  display: block;
}

.paragraph-wrapper p {
  text-align: justify;
  hyphens: auto;
}

.paragraph-wrapper a {
  text-decoration: none;
  color: var(--clr-link);
}

.even-areas {
  display: grid;
}

@media (min-width: 54em) {
  .even-areas {
    grid-template-columns: 40% 60%;
  }
}

@media (max-width: 54em) {
  .even-areas {
    grid-template-areas:
      "area-img"
      "area-content";
  }

  .hr-wrapper {
    grid-area: area-content;
  }

  .hr-img-wrapper {
    grid-area: area-img;
  }
}

.even-areas[data-type="gap"] {
  gap: var(--size-500);
}

.even-columns {
  display: grid;
  gap: 1rem;
}

@media (min-width: 50em) {
  .even-columns {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}

.text-center {
  text-align: center;
}

.text-center p {
  margin-inline: auto;
}

.fw-bold {
  font-weight: var(--fw-bold);
}
.fw-semi-bold {
  font-weight: var(--fw-semi-bold);
}
.fw-regular {
  font-weight: var(--fw-regular);
}

.fs-primary-heading {
  padding-top: var(--size-500);
  font-size: var(--fs-primary-heading);
  line-height: 1.1;
  margin-bottom: var(--size-500);
}

.padding-block-900 {
  padding: var(--size-900) 0;
}

.padding-block-700 {
  padding: var(--size-700) 0;
}

.padding-block-500 {
  padding: var(--size-500) 0;
}

.margin-block-500 {
  margin: var(--size-500) 0;
}

.padding-inline {
  padding-right: var(--size-500);
  padding-left: var(--size-500);
}

.horizontal-line {
  border-bottom: 1px solid var(--clr-neutral-white);
  width: 100%;
}

.misc-heading {
  font-size: var(--fs-650);
  font-weight: var(--fw-bold);
  color: var(--clr-gray-accent-100);
}

.misc-paragraph {
  font-size: var(--fs-400);
  hyphens: auto;
  line-height: 2;
}

.misc-paragraph h2 {
  font-size: var(--fs-625);
}

.misc-paragraph h3 {
  font-size: var(--fs-500);
}

.misc-paragraph li {
  margin-left: 3rem;
}

/*#endregion*/

/*#region Media Queries */

/* Large <1248px */
@media (min-width: 87.5em) {
}

/* Computer 1055px*/
@media (min-width: 66em) {
  /*#region Navbar */

  /*#region Hero */

  #home .even-areas {
    display: block !important;
  }

  .hr-heading {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 6%;
    margin-left: 6%;
    gap: 1rem;
  }

  .hr-header {
    font-size: 6rem;
  }

  .hr-sub-header {
    font-size: 3.5rem;
  }

  .hr-tert-header {
    font-size: 2rem;
    max-width: 36ch;
  }

  .hr-info-card {
    justify-self: flex-start;
    position: relative;
    margin-top: 2rem;
    gap: 1rem;
    width: 24rem;
    padding-top: 2rem;
  }

  .hr-info-card .btn {
    margin-top: 0.5rem !important;
  }

  /*#endregion Hero */

  /*#region Services */

  #leistungen {
    padding-top: 0rem;
    padding-bottom: 4rem;
  }

  .service-welcome-text {
    gap: 0.5rem;
    margin-bottom: 4rem !important;
  }

  .service-welcome-text-header {
    text-align: center;
    font-size: var(--fs-700) !important;
  }

  .service-welcome-text p {
    max-width: 70ch;
    hyphens: auto;
    font-size: var(--fs-625);
  }

  .service-header {
    font-size: var(--fs-700);
    margin-bottom: 4rem;
  }

  #leistungen .btn {
    width: 20rem;
    margin: 9rem auto 0 auto;
  }

  .service-card-slider-wrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: space-around;
    width: 70%;
    margin: auto;
    align-items: center;
    gap: 0;
    position: relative;
  }

  .service-card-slider-chevron {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .service-card-slider-chevron[data-type="right"] {
    justify-content: flex-end;
  }

  .service-card-slider-chevron img {
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
  }

  .service-card-slider {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 1rem;
    margin: auto;
    position: relative;
  }

  .service-card-img-wrapper {
    height: 12rem;
    width: 12rem;
  }

  .service-card-img {
    height: 7rem;
    width: 7rem;
  }

  .service-card-list-control {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    margin-bottom: -5rem;
  }

  /*#endregion Services */

  /*#region About */

  #uber-uns {
    width: 70%;
    margin: auto;
  }

  .ab-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
  }

  .ab-header {
    text-align: left;
    font-size: var(--fs-700);
  }

  .ab-text {
    max-width: 100%;
    line-height: 2.2;
  }

  .ab-img-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0;
  }

  .ab-img {
    height: 28rem;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 1rem 0 0 auto;
    border-radius: 4rem 1rem 4rem 1rem;
  }

  /*#endregion About */

  /*#region Team */

  #team {
    padding-top: 3rem;
  }

  .team-header {
    font-size: var(--fs-700);
  }

  .team-slider {
    position: relative;
    width: 60%;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .team-slider .even-areas {
    align-items: center;
    grid-template-columns: 1fr 1fr;
  }

  .team-slider-info {
    height: 100%;
    padding: 4rem 0 4rem 0;
  }

  .team-slider-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding: 0 2rem 0 1rem;
  }

  .team-slider-info-panel {
    position: relative;
  }

  .team-slider-info-name-wrapper {
  }

  .team-slider-pagination {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    align-items: flex-start;
    margin-top: 2rem;
    margin-right: 2rem;
  }

  .team-slider-dot {
    height: 1.2rem;
    width: 1.2rem;
  }

  .team-slider-info-name {
    font-size: var(--fs-750);
  }

  .team-slider-info-job {
    font-size: var(--fs-625);
  }

  .team-slider-info-mail {
    margin-top: 1rem;
    font-size: var(--fs-600);
  }

  .team-slider-control {
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  /*#endregion Team */

  /*#region Contact */

  #kontakt {
    padding-top: 4rem;
    padding-bottom: 2rem;
    position: relative;
  }

  #kontakt .even-areas {
    grid-template-columns: 1fr 1fr;
    margin: auto;
    width: 70%;
    z-index: 1;
  }

  .contact-info {
    margin-bottom: 0;
  }

  .contact-heading {
    gap: 1rem;
  }

  .contact-sub-header {
    font-size: var(--fs-625);
  }

  .contact-header {
    font-size: var(--fs-750);
  }

  .contact-info-panel-wrapper {
    gap: 1.5rem;
  }

  .contact-info-panel-icon {
    height: 3rem;
    width: 3rem;
  }

  .contact-info-panel-text {
    font-size: var(--fs-625);
  }

  .contact-appoinment-info {
    gap: 2.5rem;
  }

  .contact-appoinment-info-text {
    font-size: var(--fs-600);
    max-width: 44ch;
    line-height: 2;
  }

  .contact-appoinment-info .btn {
    width: 24rem;
    margin: auto auto auto 0;
    font-size: var(--fs-600);
  }

  .form-control label {
    font-size: var(--fs-500);
    font-weight: var(--fw-regular);
  }

  .form-control-info input {
    height: 1rem;
    width: 1rem;
  }

  /*#endregion Contact */

  /*#region Footer */

  .primary-footer-wrapper {
    height: 20rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "sprechzeiten copyright"
      "sprechzeiten  links ";
    align-items: center;
    width: 70%;
    margin: auto;
  }

  .primary-footer-officeTimes {
    grid-area: sprechzeiten;
    margin-top: 0;
    gap: 0.5rem;
  }

  .primary-footer-officeTimes-header {
    font-size: var(--fs-650);
  }

  .primary-footer-nav {
    grid-area: copyright;
    flex-direction: column;
    gap: 1rem;
    align-self: flex-end;
    margin-bottom: 2rem;
    text-align: left;
    justify-content: flex-start;
  }

  .primary-footer-nav a:hover {
    opacity: 0.7;
  }

  .primary-footer-links {
    align-self: flex-start;
    grid-area: links;
  }

  /*#endregion Footer */
}

/* Laptops 864px-1024px */
@media (min-width: 50em) and (max-width: 66em) {
}

@media (min-width: 30em) {
  .hr-info-time {
    max-width: 30ch;
  }

  .primary-header {
    padding-bottom: 2rem;
  }

  .upper-nav {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0.8rem 0;
    align-items: center;
    justify-content: center;
  }

  .upper-nav-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .upper-nav-header img {
    height: 1.8rem;
  }

  .upper-nav-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    margin: 0;
  }

  .nav-logo {
    max-width: 30rem;
  }

  .nav-extended-links {
    display: flex !important;
    gap: 1.5rem;
    align-items: center;
  }

  .nav-link {
    padding: 1rem;
    cursor: pointer;
    font-size: var(--fs-625);
    position: relative;
  }

  .nav-link[data-type="btn"] {
    background-color: var(--clr-accent-700);
    color: var(--clr-neutral-white);
    padding: 0 2rem;
    border-radius: 2rem;
    height: 3rem;
    width: 12rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-link[data-type="btn"]:hover {
    background-color: var(--clr-accent-500);
  }

  .btn-icon-hl {
    height: 2px;
    width: 100%;
    background-color: var(--clr-neutral-white);
    position: absolute;
    z-index: 800;
    bottom: 0;
    left: 0;
    margin-bottom: 0.5rem;
  }

  .btn-icon-hl::before,
  .btn-icon-hl::after {
    content: "";
    animation: hoverGrow 0.15s reverse;
  }

  .nav-link:hover .btn-icon-hl {
    background-color: var(--clr-accent-700);
    animation: hoverGrow 0.15s linear;
  }

  @keyframes hoverGrow {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }

  .container {
    padding: 0 8%;
  }

  .container p {
    max-width: 100%;
  }

  /*#endregion Navbar */
}

/* Tablet 480px-860px */
@media (min-width: 30em) and (max-width: 50em) {
}

/*#endregion Media Queries */
